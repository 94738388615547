import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import {
  getLogisticsVisibilityConfig,
  groupOrders,
  validateCollectedPayments,
} from "../../helpers/orderFunctions";
import { getEnvValueFor, groupOrdersByDeliveryArea } from "../../helpers/utils";
import { currentTabOperations } from "../../state/features/currentTab";
import { homeDeliveryOperations } from "../../state/features/homeDelivery";
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";
import NoOrders from "../noOrders";
import Order from "../order";
import Swal from "sweetalert2";
import { AnimateOrderTransitions } from "../animateOrderTransitions";
import { AnimateDeliveryAreaTransition } from "../animateDeliveryAreaTransitions";
import { AnimateTableTransition } from "../animateTableTransitions";
import { useEntryTransitionConfig } from "../../hooks/useEntryTransitionConfig";
import OrderFiltersTab from "../orderFilters";
import { ORDER_STATUS } from "../../types/orderStatus";
import useOrderFilter from "../../hooks/useOrderFilter";
interface IDeliveryInProgressOrdersProps {
  deliveryInProgressOrders: any;
  markOrderDelivered: any;
  selectedBranch: any;
  company: any;
  setCurrentTab: any;
  riderAppEnabled?: boolean;
  logisticsPartners: any[];
  companyRiders: any[];
}

function DeliveryInProgressOrders(props: IDeliveryInProgressOrdersProps) {
  const [inProgressOrders, setInProgressOrders] = useState([] as any);
  const [selecteOrderId, setSelectedOrderId] = useState(0);
  const [disableButton, setDisable] = useState(false);
  const { enableEntryTransition } = useEntryTransitionConfig();
  const { filteredOrders, updateFilterId} = useOrderFilter(inProgressOrders);

  const markOrderStatusDelivered = (orderId: number, orderPayments: any[]) => {
    props
      .markOrderDelivered(orderId, orderPayments)
      .then(() => {
        setDisable(false);
      })
      .catch((err: AxiosErrorResponse) => {
        setDisable(false);
        console.log(err);
      });
  };

  const handleValidateAssignedRider = (order: any, next: any) => {
    if (order.rider_id) {
      let isRiderExistsInCompany = props.companyRiders.find(
        (r) => r.id === order.rider_id
      );
      if (!isRiderExistsInCompany) {
        Swal.fire({
          icon: "warning",
          title: "Oops!",
          html: "<span>The rider assigned to this order was deleted by your account’s administrator. <br/> Please contact your account administrator. </span>",
          confirmButtonText: "Proceed",
          allowOutsideClick: false,
        }).then((res) => {
          next();
        });
      } else {
        next();
      }
    } else {
      next();
    }
  };

  const handlePaymentsValidation = (
    order: any,
    orderPayments: any[],
    next: any
  ) => {
    let validationEnabledCompanies: any = JSON.parse(
      getEnvValueFor("VALIDATION_ENABLED_COMPANIES") || "[]"
    );
    if (validationEnabledCompanies.includes(props.company.company_id)) {
      console.debug("[APP] Initiating payment validation");
      if (
        props.company.dm_can_record_payment &&
        (order.order_extended_info.payments.length === 0 ||
          (order.order_extended_info.payments.length === 1 &&
            order.order_extended_info.payments[0].name === "Loyalty"))
      ) {
        validateCollectedPayments(order, orderPayments, () => {
          next();
        });
      } else {
        console.debug("[APP] Skipping validation");
        next();
      }
    } else {
      next();
    }
  };

  const postOrderPayments = (orderId: any, orderPayments: any) => {
    setDisable(true);
    setSelectedOrderId(orderId);
    let order = props.deliveryInProgressOrders.find(
      (o: any) => o.order_id === orderId
    );
    if (order) {
      handleValidateAssignedRider(order, () =>
        handlePaymentsValidation(order, orderPayments, () =>
          markOrderStatusDelivered(orderId, orderPayments)
        )
      );
    } else {
      console.log("Order not found!");
    }
  };

  useEffect(() => {
    props.setCurrentTab("homeDelivery.inProgress");
  }, []);

  useEffect(() => {
    setInProgressOrders(props.deliveryInProgressOrders);
  }, [JSON.stringify(props.deliveryInProgressOrders)]);

  const filteredInProgressOrders = useMemo(() => {
    return groupOrders(filteredOrders)
  }, [filteredOrders]);

  const logisticsControlColumn = useMemo(() => {
    let show = false;
    if (inProgressOrders.length) {
      const {
        hasCompanyRiderAssignedOrders,
        hasLogisticsPartnerAssignedOrders,
      } = getLogisticsVisibilityConfig(
        inProgressOrders,
        props.logisticsPartners,
        props.riderAppEnabled
      );
      show = hasCompanyRiderAssignedOrders || hasLogisticsPartnerAssignedOrders;
    }
    return { show };
  }, [
    JSON.stringify(inProgressOrders),
    props.logisticsPartners,
    props.riderAppEnabled,
  ]);

  const hasOrders = useMemo(
    () => Object.keys(filteredInProgressOrders).length > 0,
    [JSON.stringify(filteredInProgressOrders)]
  );

  const ordersByDeliveryArea = groupOrdersByDeliveryArea(filteredInProgressOrders);

  return (
    <div className="deliveryInProgress-orders">
      <OrderFiltersTab
        currentTab={ORDER_STATUS.DELIVERY_IN_PROGRESS}
        updateSelectedFilterOption={updateFilterId}
      />
      <AnimateTableTransition
        isVisited={enableEntryTransition}
        show={hasOrders}
        component={({ ref }) => (
          <table
            className="orders-table"
            cellPadding="0"
            cellSpacing="0"
            ref={ref}>
            <thead>
              <tr>
                <th></th>
                <th style={{ width: "180px" }}>
                  <FormattedMessage id="order.no" />.
                </th>
                <th>
                  <FormattedMessage id="order.taken_at" />
                </th>
                <th>
                  <FormattedMessage id="order.elapsed_time" />
                </th>
                {!props.riderAppEnabled ? (
                  <th>
                    <FormattedMessage id="order.driver" />
                  </th>
                ) : null}
                <th>
                  <FormattedMessage id="order.location" />
                </th>
                <th>
                  <FormattedMessage id="order.amount" />
                </th>
                {logisticsControlColumn.show ? (
                  props.riderAppEnabled ? (
                    <th className="text-center">
                      <FormattedMessage id="order.riders" />
                    </th>
                  ) : (
                    <th className="text-center">
                      <FormattedMessage id="order.partner_drivers" />
                    </th>
                  )
                ) : null}
                <th className="text-center">
                  <FormattedMessage id="order.status" />
                </th>
              </tr>
            </thead>
            {ordersByDeliveryArea.map((item: any) => (
              <AnimateDeliveryAreaTransition
                key={item.deliveryArea}
                show={Boolean(item.orders.length)}
                component={({ areaRef }) => (
                  <tbody
                    ref={areaRef}
                    key={item.deliveryArea}
                    className="orders-list-table-body">
                    <tr className="location-header">
                      <td colSpan={9}>
                        {item.deliveryArea == "null"
                          ? "N/A"
                          : item.deliveryArea}
                      </td>
                    </tr>
                    <AnimateOrderTransitions
                      orders={item.orders}
                      enableEntryTransition={enableEntryTransition}
                      component={({ item: order }) => (
                        <Order
                          orderType="homeDelivery"
                          orderStatus="in_progress"
                          key={order.order_id}
                          disableButton={
                            order.order_id === selecteOrderId && disableButton
                          }
                          handleOrderState={postOrderPayments}
                          item={order}
                          showEmptyCell={logisticsControlColumn.show}
                        />
                      )}
                    />
                  </tbody>
                )}
              />
            ))}
          </table>
        )}
      />
      {!hasOrders ? <NoOrders /> : null}
    </div>
  );
}
const mapStateToProps = (state: any) => {
  let deliveryInProgressOrders =
    state.order.home_delivery.in_progress_orders.orders || [];
  let selectedBranch = state.company?.selected_branch;
  let company = state.company.company_details;
  let riderAppEnabled = state.company.company_details.rider_app_enabled;
  let logisticsPartners = state.logisticPartners;
  let companyRiders = state.drivers.company_drivers;

  return {
    deliveryInProgressOrders,
    selectedBranch,
    company,
    riderAppEnabled,
    logisticsPartners,
    companyRiders,
  };
};

const mapDispatchToProps = {
  markOrderDelivered: homeDeliveryOperations.markOrderDelivered,
  setCurrentTab: currentTabOperations.setCurrentTab,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryInProgressOrders);
