import React from "react";
import { useCompanySyncEvents } from "../../hooks/useCompanySyncEvents";
import { hardRefresh } from "../../helpers/utils";
import { usePaymentTypesSyncEvents } from "../../hooks/usePaymentTypesSyncEvents";
import { paymentsOperations } from "../../state/features/payments";
import { useLocationSyncEvents } from "../../hooks/useLocationSyncEvents";
import { useRidersSyncEvents } from "../../hooks/useRidersSyncEvents";
import { driverOperations } from "../../state/features/drivers";
import { connect } from "react-redux";
import { AxiosErrorResponse } from "../../types/axiosMiddleware";
import { useLogisticsPartnersSyncEvents } from "../../hooks/useLogisticsPartnersSyncEvents";
import { logisticPartnersOperations } from "../../state/features/logisticPartners";
import { usePrintSettingsSyncEvents } from "../../hooks/usePrintSettingsSyncEvents";
import { printSettingsOperations } from "../../state/features/printSettings";
import { useRiderProximityEvents } from "../../hooks/useRiderProximityEvents";

interface IPusherEventProps {
  children: React.ReactNode;
  fetchPayments: () => Promise<any>;
  fetchDrivers: (company_id: number) => Promise<any>;
  fetchLogisticPartners: () => Promise<any>;
  fetchPrintSettings: () => Promise<any>;
}

const PusherEvents = ({
  children,
  fetchPayments,
  fetchDrivers,
  fetchLogisticPartners,
  fetchPrintSettings,
}: IPusherEventProps) => {
  useCompanySyncEvents({
    callback: (data) => {
      console.debug("Company details have been updated! Refreshing page...");
      setTimeout(() => {
        hardRefresh();
      }, 200);
    },
  });

  usePaymentTypesSyncEvents({
    callback: (data) => {
      fetchPayments();
    },
  });

  useLocationSyncEvents({
    callback: (data) => {
      console.debug("Branches have been updated! Refreshing page...");
      setTimeout(() => {
        hardRefresh();
      }, 200);
    },
  });

  useRidersSyncEvents({
    callback: (data) => {
      fetchDrivers(data.company_id).catch((err: AxiosErrorResponse) =>
        console.log(err)
      );
    },
  });

  useLogisticsPartnersSyncEvents({
    callback: (data) => {
      fetchLogisticPartners().catch((err: AxiosErrorResponse) => {
        console.log(err);
      });
    },
  });

  usePrintSettingsSyncEvents({
    callback: () => {
      fetchPrintSettings();
    },
  });

  useRiderProximityEvents({
    callback: (data) => {
      fetchPrintSettings();
    },
  });

  return <>{children}</>;
};

const mapDispatchToProps = {
  fetchPayments: paymentsOperations.fetchPayments,
  fetchDrivers: driverOperations.fetchDrivers,
  fetchLogisticPartners: logisticPartnersOperations.fetchLogisticPartners,
  fetchPrintSettings: printSettingsOperations.fetchPrintSettings,
};

export default connect(undefined, mapDispatchToProps)(PusherEvents);
