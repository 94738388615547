import { combineReducers } from "redux";
import * as types from "./types";
import * as orderEventTypes from "../orderEvents/types";
import {
  getDeliveredOrderStats,
  getOrdersStats,
  isOrderPayloadPresentIn,
  removeOrderFromList,
} from "../../../helpers/orderFunctions";
import * as orderTypes from "../order/types";

type OrderState = {
  pending_orders: number;
  is_loading: boolean,
  count: number,
  orders: any[],
}

const initialOrdersState: OrderState = {
  pending_orders: 0,
  is_loading: true,
  count: 0,
  orders: [],
};


const newOrdersReducer = (
  state: OrderState = initialOrdersState,
  action: any
) => {
  switch (action.type) {
    case types.FETCH_NEW_ORDERS_SUCCESS: {
      return Object.assign(
        { ...state },
        { is_loading: false, pending_orders: 0 },
        getOrdersStats(action.payload.data || [])
      );
    }
    // On clicking mark-as-ready cta - filter and remove order on dispatch success
    case types.SET_ORDER_MARK_AS_READY_SUCCESS: {
      let orderId = action.meta.previousAction.param.order_id;
      return Object.assign(
        { ...state },
        removeOrderFromList(orderId, state.orders)
      );
    }
    case orderTypes.FETCH_ORDER_SUCCESS: {
      let index = state.orders.findIndex(
        (order: any) => order.order_id === action.payload.data.order_id
      );
      if (index === -1) {
        return state;
      }
      let updatedOrders = state.orders;
      updatedOrders[index] = action.payload.data;
      return Object.assign({ ...state }, getOrdersStats(updatedOrders));
    }

    // Remove the order from list once it completes 24 hours.
    case orderTypes.REMOVE_EXPIRED_ORDER: {
      let orderId = action.payload;
      return Object.assign(
        { ...state },
        removeOrderFromList(orderId, state.orders)
      );
    }

    // Pusher event - filter and remove ready order
    case orderEventTypes.PUSHER_RECEIVED_ORDER_MARKED_AS_READY: {
      let orderId = action.payload.order_id;
      return Object.assign(
        { ...state },
        removeOrderFromList(orderId, state.orders)
      );
    }

    case orderEventTypes.PUSHER_RECEIVED_ORDER_CONFIRMED:
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CREATED: {
      let pusherData = action.payload;
      let pusherDataHasOrderPayload = isOrderPayloadPresentIn(pusherData);
      // if order is not a new home delivery order, do nothing
      if (
        pusherData.order_status !== "new" ||
        pusherData.scheduled ||
        pusherData.takeaway
      ) {
        return state;
      }
      let index = state.orders.findIndex(
        (order: any) => order.order_id === action.payload.order_id
      );
      // order is already in the list, do nothing
      if (index !== -1) {
        return state;
      }
      if (!pusherDataHasOrderPayload) {
        return Object.assign(
          { ...state },
          {
            count: state.count + 1,
            pending_orders: state.pending_orders + 1,
          }
        );
      }
      return Object.assign(
        { ...state },
        getOrdersStats(
          [...state.orders, action.payload.order_payload],
          state.pending_orders
        )
      );
    }

    case orderEventTypes.PUSHER_RECEIVED_PARTNER_RIDER_ASSIGNED:
    case orderEventTypes.PUSHER_RECEIVED_COMPANY_RIDER_ASSIGNED:
    case orderEventTypes.PUSHER_RECEIVED_RIDER_ASSIGNED:
    case orderEventTypes.PUSHER_RECEIVED_RIDER_UNASSIGNED: {
      let pusherData = action.payload;
      let pusherDataHasOrderPayload = isOrderPayloadPresentIn(pusherData);
      if (
        pusherData.order_status !== "new" ||
        pusherData.scheduled ||
        pusherData.takeaway
      ) {
        return state;
      }
      let index = state.orders.findIndex(
        (order: any) => order.order_id === action.payload.order_id
      );
      if (index === -1) {
        return state;
      }
      if (!pusherDataHasOrderPayload) {
        return Object.assign(
          { ...state },
          {
            count: state.count + 1,
            pending_orders: state.pending_orders + 1,
          }
        );
      }
      let updatedOrders = state.orders;
      updatedOrders[index] = pusherData.order_payload;
      return Object.assign(
        { ...state },
        getOrdersStats(updatedOrders, state.pending_orders)
      );
    }

    case orderEventTypes.PUSHER_RECEIVED_ORDER_VOIDED: {
      // Remove order from the state for voided order
      let pusherData = action.payload;
      return Object.assign(
        { ...state },
        removeOrderFromList(pusherData.order_id, state.orders)
      );
    }

    default:
      return state;
  }
};

const unconfirmedOrdersReducer = (
  state: OrderState = initialOrdersState,
  action: any
) => {
  switch (action.type) {
    case types.FETCH_UNCONFIRMED_ORDERS_SUCCESS: {
      return Object.assign(
        { ...state },
        { is_loading: false, pending_orders: 0 },
        getOrdersStats(action.payload.data)
      );
    }
    case types.SET_UNCONFIRMED_ORDER_CONFIRM_SUCCESS: {
      let orderId = action.meta.previousAction.param.order_id;
      return Object.assign(
        { ...state },
        removeOrderFromList(orderId, state.orders)
      );
    }
    case types.SET_UNCONFIRMED_ORDER_REJECT_FAIL: {
      // If order is already reject (404 status), remove order from the list
      if (action.error !== undefined && action.error.status === 404) {
        let orderId = action.meta.previousAction.param.order_id;
        return Object.assign(
          { ...state },
          removeOrderFromList(orderId, state.orders)
        );
      }
      return state;
    }
    case types.SET_UNCONFIRMED_ORDER_REJECT_SUCCESS: {
      let orderId = action.meta.previousAction.param.order_id;
      return Object.assign(
        { ...state },
        removeOrderFromList(orderId, state.orders)
      );
    }
    case orderTypes.FETCH_ORDER_SUCCESS: {
      let index = state.orders.findIndex(
        (order: any) => order.order_id === action.payload.data.order_id
      );
      if (index === -1) {
        return state;
      }
      let updatedOrders = state.orders;
      updatedOrders[index] = action.payload.data;
      return Object.assign({ ...state }, getOrdersStats(updatedOrders));
    }
    case orderTypes.REMOVE_EXPIRED_ORDER: {
      // Remove the order from list once it completes 24 hours.
      let orderId = action.payload;
      return Object.assign(
        { ...state },
        removeOrderFromList(orderId, state.orders)
      );
    }
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CREATED: {
      let pusherData = action.payload;
      let index = state.orders.findIndex(
        (order: any) => order.order_id === pusherData.order_id
      );
      let pusherDataHasOrderPayload = isOrderPayloadPresentIn(pusherData);
      if (
        pusherData.order_type === "online" &&
        pusherData.order_status === "unconfirmed" &&
        !pusherData.takeaway
      ) {
        if (index !== -1) {
          return state;
        }
        if (!pusherDataHasOrderPayload) {
          return Object.assign(
            { ...state },
            {
              count: state.count + 1,
              pending_orders: state.pending_orders + 1,
            }
          );
        }
        return Object.assign(
          { ...state },
          getOrdersStats(
            [...state.orders, pusherData.order_payload],
            state.pending_orders
          )
        );
      }
      return state;
    }
    case orderEventTypes.PUSHER_RECEIVED_ORDER_REJECTED:
    case orderEventTypes.PUSHER_RECEIVED_ORDER_CONFIRMED: {
      let orderId = action.payload.order_id;
      return Object.assign(
        { ...state },
        removeOrderFromList(orderId, state.orders)
      );
    }

    default:
      return state;
  }
};

const readyOrdersReducer = (state: OrderState = initialOrdersState, action: any) => {
  switch (action.type) {
    case types.FETCH_READY_ORDERS_SUCCESS: {
      return Object.assign(
        { ...state },
        { is_loading: false, pending_orders: 0 },
        getOrdersStats(action.payload.data || [])
      );
    }
    case types.SET_ORDER_ASSIGN_RIDER_SUCCESS:
    case types.SET_ORDER_MARK_AS_PICKUP_SUCCESS: {
      let orderId = action.meta.previousAction.param.order_id;
      return Object.assign(
        { ...state },
        removeOrderFromList(orderId, state.orders)
      );
    }
    case orderTypes.FETCH_ORDER_SUCCESS: {
      let index = state.orders.findIndex(
        (order: any) => order.order_id === action.payload.data.order_id
      );
      if (index === -1) {
        return state;
      }
      let updatedOrders = state.orders;
      updatedOrders[index] = action.payload.data;
      return Object.assign({ ...state }, getOrdersStats(updatedOrders));
    }
    case orderTypes.REMOVE_EXPIRED_ORDER: {
      // Remove the order from list once it completes 24 hours.
      let orderId = action.payload;
      return Object.assign(
        { ...state },
        removeOrderFromList(orderId, state.orders)
      );
    }
    case orderEventTypes.PUSHER_RECEIVED_ORDER_MARKED_AS_READY: {
      let pusherData = action.payload;
      let pusherDataHasOrderPayload = isOrderPayloadPresentIn(pusherData);
      let index = state.orders.findIndex(
        (order: any) => order.order_id === pusherData.order_id
      );
      if (index !== -1) {
        return state;
      }
      if (!pusherDataHasOrderPayload) {
        return { ...state, count: state.count + 1, pending_orders: state.pending_orders + 1};
      }
      return Object.assign(
        { ...state },
        getOrdersStats(
          [...state.orders, pusherData.order_payload],
          state.pending_orders
        )
      );
    }
    case orderEventTypes.PUSHER_RECEIVED_PARTNER_RIDER_ASSIGNED:
    case orderEventTypes.PUSHER_RECEIVED_RIDER_ASSIGNED: {
      let pusherData = action.payload;
      let pusherDataHasOrderPayload = isOrderPayloadPresentIn(pusherData);
      let index = state.orders.findIndex(
        (order: any) => order.order_id === pusherData.order_id
      );
      if (index === -1) {
        return state;
      }
      if (!pusherDataHasOrderPayload) {
        return Object.assign(
          { ...state },
          { pending_orders: state.pending_orders + 1 }
        );
      }
      let orderId = action.payload.order_id;
      return Object.assign(
        { ...state },
        removeOrderFromList(orderId, state.orders)
      );
    }
    case orderEventTypes.PUSHER_RECEIVED_COMPANY_RIDER_ASSIGNED: {
      let pusherData = action.payload;
      let index = state.orders.findIndex(
        (order) => order.order_id === pusherData.order_id
      );
      if (index === -1) {
        return state;
      }
      let updatedOrders = state.orders;
      updatedOrders[index] = pusherData.order_payload;
      return Object.assign({ ...state }, getOrdersStats(updatedOrders));
    }
    case orderEventTypes.PUSHER_RECEIVED_RIDER_UNASSIGNED: {
      let pusherData = action.payload;
      let pusherDataHasOrderPayload = isOrderPayloadPresentIn(pusherData);
      let index = state.orders.findIndex(
        (order: any) => order.order_id === pusherData.order_id
      );
      if (
        pusherData.order_status !== "ready" ||
        pusherData.scheduled ||
        pusherData.takeaway
      ) {
        return state;
      }
      if (index === -1) {
        return state;
      }
      if (!pusherDataHasOrderPayload) {
        return Object.assign(
          { ...state },
          {
            count: state.count + 1,
            pending_orders: state.pending_orders + 1,
          }
        );
      }
      let updatedOrders = state.orders;
      updatedOrders[index] = pusherData.order_payload;
      return Object.assign(
        { ...state },
        getOrdersStats(updatedOrders, state.pending_orders)
      );
    }
    case orderEventTypes.PUSHER_RECEIVED_ORDER_MARKED_AS_PICKED_UP: {
      let pusherData = action.payload;
      if (["ready", "in_progress"].includes(pusherData.order_status)) {
        return Object.assign(
          { ...state },
          removeOrderFromList(pusherData.order_id, state.orders)
        );
      }
      return state;
    }
    case orderEventTypes.PUSHER_RECEIVED_ORDER_VOIDED: {
      let pusherData = action.payload;
      return Object.assign(
        { ...state },
        removeOrderFromList(pusherData.order_id, state.orders)
      );
    }

    default:
      return state;
  }
};

const inProgressOrdersReducer = (
  state: OrderState = initialOrdersState,
  action: any
) => {
  switch (action.type) {
    case types.FETCH_IN_PROGRESS_ORDERS_SUCCESS: {
      return Object.assign(
        { ...state },
        { is_loading: false, pending_orders: 0 },
        getOrdersStats(action.payload.data || [])
      );
    }
    case types.SET_ORDER_MARK_AS_DELIVERED_SUCCESS: {
      let orderId = action.meta.previousAction.param.order_id;
      return Object.assign(
        { ...state },
        removeOrderFromList(orderId, state.orders)
      );
    }
    case types.SET_ORDER_ASSIGN_RIDER_SUCCESS:
    case types.SET_ORDER_MARK_AS_PICKUP_SUCCESS:
      return state;
    case orderTypes.FETCH_ORDER_SUCCESS: {
      let index = state.orders.findIndex(
        (order: any) => order.order_id === action.payload.data.order_id
      );
      if (index === -1) {
        return state;
      }
      let updatedOrders = state.orders;
      updatedOrders[index] = action.payload.data;
      return Object.assign({ ...state }, getOrdersStats(updatedOrders));
    }
    case orderTypes.REMOVE_EXPIRED_ORDER: {
      // Remove the order from list once it completes 24 hours.
      return Object.assign(
        { ...state },
        removeOrderFromList(action.payload, state.orders)
      );
    }
    case orderEventTypes.PUSHER_RECEIVED_PARTNER_RIDER_ASSIGNED:
    case orderEventTypes.PUSHER_RECEIVED_COMPANY_RIDER_ASSIGNED:
    case orderEventTypes.PUSHER_RECEIVED_RIDER_ASSIGNED:
    case orderEventTypes.PUSHER_RECEIVED_ORDER_MARKED_AS_PICKED_UP: {
      let pusherData = action.payload;
      let pusherDataHasOrderPayload = isOrderPayloadPresentIn(pusherData);
      let index = state.orders.findIndex(
        (order: any) => order.order_id === action.payload.order_id
      );
      if (
        pusherData.order_status === "ready" ||
        pusherData.order_status === "new"
      ) {
        return state;
      }
      if (index !== -1) {
        return state;
      }
      if (!pusherDataHasOrderPayload) {
        return Object.assign(
          { ...state },
          { count: state.count + 1, pending_orders: state.pending_orders + 1 }
        );
      }
      return Object.assign(
        { ...state },
        getOrdersStats(
          [...state.orders, pusherData.order_payload],
          state.pending_orders
        )
      );
    }
    case orderEventTypes.PUSHER_RECEIVED_ORDER_MARKED_AS_DELIVERED:
    case orderEventTypes.PUSHER_RECEIVED_ORDER_VOIDED: {
      let orderId = action.payload.order_id;
      return Object.assign(
        { ...state },
        removeOrderFromList(orderId, state.orders)
      );
    }
    case orderEventTypes.PUSHER_RECEIVED_TIP_COLLECTED: {
      let pusherData = action.payload;
      let pusherDataHasOrderPayload = isOrderPayloadPresentIn(pusherData);
      let index = state.orders.findIndex(
        (order: any) => order.order_id === action.payload.order_id
      );
      if (
        pusherData.order_status === "ready" ||
        pusherData.order_status === "new"
      ) {
        return state;
      }
      if (index === -1) {
        return state;
      }
      if (!pusherDataHasOrderPayload) {
        return Object.assign(
          { ...state },
          { pending_orders: state.pending_orders + 1 }
        );
      }
      let updatedOrders = state.orders;
      updatedOrders[index] = pusherData.order_payload;
      return Object.assign(
        { ...state },
        getOrdersStats(updatedOrders, state.pending_orders)
      );
    }
    default:
      return state;
  }
};

const deliveredOrdersReducer = (
  state: any = initialOrdersState,
  action: any
) => {
  switch (action.type) {
    case types.FETCH_RIDER_STATS_SUCCESS:
      let newState = getDeliveredOrderStats(action.payload.data || []);
      return { ...newState, is_loading: false, pending_orders: 0 };
    case types.SET_ORDER_MARK_AS_DELIVERED_SUCCESS:
      // cta from previous tab
      return { ...state, pending_orders: state.pending_orders + 1 };
    case orderEventTypes.PUSHER_RECEIVED_ORDER_MARKED_AS_DELIVERED:
      return {
        ...state,
        count: state.count + 1,
        pending_orders: state.pending_orders + 1,
      };
    case types.SET_DELIVERED_ORDERS_REFRESH_REQUIRED:
    case orderEventTypes.PUSHER_RECEIVED_TIP_COLLECTED:
      return { ...state, pending_orders: state.pending_orders + 1 };
    default:
      return state;
  }
};

// TODO: Revisit this reducer for better exp
// Add an initial state for loading
const deliveredDriverOrderDetailsReducer = (state: any = [], action: any) => {
  let index, orderList;
  switch (action.type) {
    case types.FETCH_RIDER_ORDERS_SUCCESS:
      return action.payload.data;
    case types.RESET_DELIVERED_DRIVER_ORDER_DETAILS:
      return action.payload;
    case orderEventTypes.PUSHER_RECEIVED_ORDER_REPRINTED:
      index = state.findIndex((order: any) => {
        return order.order_id === action.payload.order_id;
      });
      if (index === -1) {
        return state;
      }
      orderList = state;
      orderList[index] = action.payload.order_payload;
      return [...orderList];
    case orderTypes.FETCH_ORDER_SUCCESS:
      index = state.findIndex((order: any) => {
        return order.order_id === action.payload.data.order_id;
      });
      if (index === -1) {
        return state;
      }
      orderList = state;
      orderList[index] = action.payload.data;
      return [...orderList];
    default:
      return state;
  }
};

const reducer = combineReducers({
  new_orders: newOrdersReducer,
  unconfirmed_orders: unconfirmedOrdersReducer,
  ready_orders: readyOrdersReducer,
  in_progress_orders: inProgressOrdersReducer,
  delivered_orders: deliveredOrdersReducer,
  delivered_driver_order_details: deliveredDriverOrderDetailsReducer,
});

export default reducer;
