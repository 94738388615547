import { useEffect, useMemo, useRef } from "react";
import { useAppStateSelector } from "./useAppStateSelector";
import { usePusher } from "./usePusher";
import { Channel } from "pusher-js";

const useRidersSyncEvents = ({ callback = (data: any) => {} }) => {
  const company = useAppStateSelector((state) => state.company.company_details);
  const pusher = usePusher();
  let channel = useRef<Channel>(null);

  const companyDetails = useMemo(() => {
    let res = null;
    if (Object.keys(company).length > 1) {
      res = company;
    }
    return res;
  }, [JSON.stringify(company)]);

  useEffect(() => {
    if (companyDetails) {
      console.debug(`[PUSHER] Subscribing to riders sync events`);
      channel.current = pusher.subscribe(
        `riders_synchronized_${company.company_id}`
      );
      channel.current.bind("riders_synchronized", (data: any) => {
        console.debug(`[PUSHER] [riders_synchronized]`);
        callback(data);
      });
    }
    return () => {
      if (channel.current) {
        channel.current?.unsubscribe();
      }
    };
  }, [companyDetails]);
};

export { useRidersSyncEvents };
