import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { currentTabOperations } from "../../state/features/currentTab";
import { homeDeliveryOperations } from "../../state/features/homeDelivery";
import DeliveredDriver from "../deliveredDriver";
import NoOrders from "../noOrders";
import RiderStatsSection from "../riderStatsSection";

interface IDeliveredOrdersProps {
  deliveredOrders: any;
  fetchDeliveredDriverOrderDetails: any;
  selectedBranch: any;
  company: any;
  setCurrentTab: any;
}

function DeliveredOrders(props: IDeliveredOrdersProps) {
  const [deliveredOrders, setDeliveredOrders] = useState([] as any);
  const [filteredRiders, setFilteredRiders] = useState([] as any);
  const [selectedDriver, selectDriver] = useState({} as any);
  const [isExpandable, setExpandable] = useState(false);
  const [expandDriverId, setExpandDriverId] = useState(0 as any);

  useEffect(() => {
    let allDrivers = { name: "All Riders" };
    selectDriver(allDrivers);
    props.setCurrentTab("homeDelivery.delivered");
  }, []);

  useEffect(() => {
    if (selectedDriver.name === "All Riders") {
      setDeliveredOrders(props.deliveredOrders);
    } else {
      setDeliveredOrders([selectedDriver]);
    }
  }, [selectedDriver]);

  useEffect(() => {
    setDeliveredOrders(props.deliveredOrders);
    setFilteredRiders(props.deliveredOrders);
  }, [JSON.stringify(props.deliveredOrders)]);

  const covertTimeIntoMinutes = (time: any) => {
    let minutes = Math.floor(time / 60);
    return minutes;
  };

  const handleExpand = (driverId: any) => {
    setExpandDriverId(driverId);
  };

  return (
    <div className="delivered-orders">
      <RiderStatsSection
        selectDriver={selectDriver}
        selectedDriver={selectedDriver}
        riderStats={[{ name: "All Riders" }, ...filteredRiders]}
      />
      {deliveredOrders.length ? (
        <table className="orders-table" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th style={{ width: "50%" }}>
                <FormattedMessage id="order.driver_name" />
              </th>
              <th className="text-center" style={{ width: "8%" }}>
                <FormattedMessage id="order.total_orders" />
              </th>
              <th className="text-right" style={{ width: "8%" }}>
                <FormattedMessage id="order.total_amount" />
              </th>
              <th className="text-center" style={{ width: "10%" }}>
                <FormattedMessage id="order.avg_del_time" />
              </th>
            </tr>
          </thead>
          <tbody>
            {deliveredOrders.map((driver: any, index: number) => {
              return (
                <DeliveredDriver
                  isExpandable={driver.id === expandDriverId ? true : false}
                  handleExpand={(driverId: any) => handleExpand(driverId)}
                  key={index}
                  driver={driver}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <NoOrders />
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let deliveredOrders = state.order.home_delivery.delivered_orders.orders || [];
  let selectedBranch = state.company?.selected_branch;
  let company = state.company.company_details;

  return { deliveredOrders, company, selectedBranch };
};

const mapDispatchToProps = {
  fetchDeliveredDriverOrderDetails:
    homeDeliveryOperations.fetchDeliveredDriverOrderDetails,
  setCurrentTab: currentTabOperations.setCurrentTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeliveredOrders);
