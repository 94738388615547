import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { homeDeliveryOperations } from "../../state/features/homeDelivery";
import TabNavigation from "../../components/tabs";
import UnconfirmedOrders from "../../components/unconfirmedOrders";
import NewOrders from "../../components/newOrders";
import WaitingForCollectionOrders from "../../components/waitingForCollectionOrders";
import DeliveryInProgressOrders from "../../components/deliveryInProgressOrders";
import DeliveredOrders from "../../components/deliveredOrders";
import { branchOperations } from "../../state/features/branches";
import { driverOperations } from "../../state/features/drivers";
import { useSelectedBranch } from "../../helpers/orderFunctions";
import { logisticPartnersOperations } from "../../state/features/logisticPartners";
import { FormattedMessage } from 'react-intl';
import type { AxiosErrorResponse } from "../../types/axiosMiddleware";

interface HomeDeliveriesProps {
  company: any;
  branches: any;
  homeDeliveryOrders: any;
  fetchNewOrders: any;
  fetchInProgressOrders: any;
  fetchReadyOrders: any;
  fetchUnconfirmedOrders: any;
  fetchDeliveredOrders: any;
  selectedBranch: any;
  selectBranch: any;
  fetchDrivers: any;
  currentTab: any;
  fetchLogisticPartners: any;
}

function HomeDeliveries(props: HomeDeliveriesProps) {
  const [hideUnconfirmed, setHideUnconfirmed] = useState(true);
  useEffect(() => {
    if (Object.keys(props.selectedBranch).length > 0) {
      props
        .fetchUnconfirmedOrders(useSelectedBranch(props.selectedBranch))
        .catch((err: AxiosErrorResponse) => console.log(err));
      props
        .fetchNewOrders(useSelectedBranch(props.selectedBranch))
        .catch((err: AxiosErrorResponse) => console.log(err));
      props
        .fetchReadyOrders(useSelectedBranch(props.selectedBranch))
        .catch((err: AxiosErrorResponse) => console.log(err));
      props
        .fetchInProgressOrders(useSelectedBranch(props.selectedBranch))
        .catch((err: AxiosErrorResponse) => console.log(err));
      props
        .fetchDeliveredOrders(useSelectedBranch(props.selectedBranch))
        .catch((err: AxiosErrorResponse) => console.log(err));
    }
  }, [props.selectedBranch]);

  useEffect(() => {
    if (
      props.currentTab.length > 0 &&
      Object.keys(props.homeDeliveryOrders).length > 0 &&
      Object.keys(props.selectedBranch).length > 0
    ) {
      switch (props.currentTab) {
        case "homeDelivery.unconfirmed":
          if (props.homeDeliveryOrders.unconfirmed_orders.pending_orders) {
            props
              .fetchUnconfirmedOrders(useSelectedBranch(props.selectedBranch))
              .catch((err: AxiosErrorResponse) => console.log(err));
          }
          break;
        case "homeDelivery.new":
          if (props.homeDeliveryOrders.new_orders.pending_orders) {
            props
              .fetchNewOrders(useSelectedBranch(props.selectedBranch))
              .catch((err: AxiosErrorResponse) => console.log(err));
          }
          break;
        case "homeDelivery.ready":
          if (props.homeDeliveryOrders.ready_orders.pending_orders) {
            props
              .fetchReadyOrders(useSelectedBranch(props.selectedBranch))
              .catch((err: AxiosErrorResponse) => console.log(err));
          }
          break;
        case "homeDelivery.inProgress":
          if (props.homeDeliveryOrders.in_progress_orders.pending_orders) {
            props
              .fetchInProgressOrders(useSelectedBranch(props.selectedBranch))
              .catch((err: AxiosErrorResponse) => console.log(err));
          }
          break;
        case "homeDelivery.delivered":
          if (props.homeDeliveryOrders.delivered_orders.pending_orders) {
            props
              .fetchDeliveredOrders(useSelectedBranch(props.selectedBranch))
              .catch((err: AxiosErrorResponse) => console.log(err));
          }
          break;
        default:
          "";
      }
    }
    if (props.company.user_permissions?.includes("weborder.manage")) {
      setHideUnconfirmed(false);
    } else if (props.company.user_permissions?.includes("all.manage")) {
      setHideUnconfirmed(false);
    } else {
      setHideUnconfirmed(true);
    }
  }, [
    props.currentTab,
    props.homeDeliveryOrders,
    props.selectedBranch,
    props.company,
  ]);

  const tabs = [
    {
      name: <FormattedMessage id="tab.unconfirmed"/>,
      path: "/home-delivery/unconfirmed",
      orders_count: props.homeDeliveryOrders.unconfirmed_orders.count,
      is_loading: props.homeDeliveryOrders.unconfirmed_orders.is_loading,
      is_hidden: hideUnconfirmed,
    },
    {
      name: <FormattedMessage id="tab.new_order"/>,
      path: "/home-delivery/new",
      orders_count: props.homeDeliveryOrders.new_orders.count,
      is_loading: props.homeDeliveryOrders.new_orders.is_loading,
      is_hidden: false,
    },
    {
      name:<FormattedMessage id="tab.waiting_for_pick"/>,
      path: "/home-delivery/waiting-for-pickup",
      orders_count: props.homeDeliveryOrders.ready_orders.count,
      is_loading: props.homeDeliveryOrders.ready_orders.is_loading,
      is_hidden: false,
    },
    {
      name: <FormattedMessage id="tab.delivery_in_progress"/>,
      path: "/home-delivery/in-progress",
      orders_count: props.homeDeliveryOrders.in_progress_orders.count,
      is_loading: props.homeDeliveryOrders.in_progress_orders.is_loading,
      is_hidden: false,
    },
    {
      name: <FormattedMessage id="tab.delivered"/>,
      path: "/home-delivery/delivered",
      orders_count: props.homeDeliveryOrders.delivered_orders.count,
      is_loading: props.homeDeliveryOrders.delivered_orders.is_loading,
      is_hidden: false,
    },
  ];

  return (
    <div>
      <TabNavigation tabInfo={tabs} />
      <Switch>
        <Route path={tabs[0].path} component={UnconfirmedOrders} />
        <Route path={tabs[1].path} component={NewOrders} />
        <Route path={tabs[2].path} component={WaitingForCollectionOrders} />
        <Route path={tabs[3].path} component={DeliveryInProgressOrders} />
        <Route path={tabs[4].path} component={DeliveredOrders} />
      </Switch>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  let homeDeliveryOrders = state.order.home_delivery;
  let selectedBranch = state.company?.selected_branch;
  let branches = state.branches || [];
  let company = state.company.company_details;
  let currentTab = state.currentTab;

  return { homeDeliveryOrders, branches, selectedBranch, company, currentTab };
};

const mapDispatchToProps = {
  fetchNewOrders: homeDeliveryOperations.fetchNewOrders,
  fetchInProgressOrders: homeDeliveryOperations.fetchInProgressOrders,
  fetchReadyOrders: homeDeliveryOperations.fetchReadyOrders,
  fetchUnconfirmedOrders: homeDeliveryOperations.fetchUnconfirmedOrders,
  fetchDeliveredOrders: homeDeliveryOperations.fetchDeliveredOrders,
  fetchDrivers: driverOperations.fetchDrivers,
  selectBranch: branchOperations.selectBranch,
  fetchLogisticPartners: logisticPartnersOperations.fetchLogisticPartners,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeDeliveries);
